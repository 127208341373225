import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import styles from "./index.module.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <div className={styles.wrapper}>
    <App />
  </div>
);
